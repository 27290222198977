.App {
  padding: 8px;
}

.header {
  padding: 8px;
}
.MuiOutlinedInput-input {
  padding-top: 8.5px !important;
  padding-bottom: 8.5px !important;
}

input.MuiOutlinedInput-input {
  width: 130px;
}